<template>
  <Card title="液位计(二号池)" width="36vw" height="24vw">
    <div style="height: 90%;" id="rain-chart">
    </div>
  </Card>
</template>
<script>
import * as echarts from "echarts";

export default {
  data() {
    return {};
  },
  mounted() {
    this.draw();
  },
  methods: {
    draw() {
      this.myEchart?.dispose(); //销毁

      this.myEchart = echarts.init(document.getElementById("rain-chart"));
      // 数据
      const data = [
        ["2000-06-24", 94],
        ["2000-06-25", 71],
        ["2000-06-26", 106],
        ["2000-06-27", 84],
        ["2000-06-28", 93],
        ["2000-06-29", 85],
        ["2000-06-30", 73],
        ["2000-07-01", 83],
        ["2000-07-02", 125],
        ["2000-07-03", 107],
        ["2000-07-04", 82],
        ["2000-07-05", 44],
        ["2000-07-06", 72],
        ["2000-07-07", 106],
        ["2000-07-08", 107],
        ["2000-07-09", 66],
        ["2000-07-10", 91],
        ["2000-07-11", 92],
        ["2000-07-12", 113],
        ["2000-07-13", 107],
        ["2000-07-14", 131],
        ["2000-07-15", 111],
        ["2000-07-16", 64],
        ["2000-07-17", 69],
        ["2000-07-18", 88],
        ["2000-07-19", 77],
        ["2000-07-20", 83],
        ["2000-07-21", 111],
        ["2000-07-22", 57],
        ["2000-07-23", 55],
        ["2000-07-24", 60],
      ];
      const dateList = data.map(function (item) {
        return item[0];
      });
      const valueList = data.map(function (item) {
        return item[1];
      });

      let option = {
        tooltip: {
          trigger: "axis",
        },
        xAxis: [
          {
            boundaryGap: false,
            data: dateList,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false, //隐藏x轴线
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#1d454d"],
              },
            },
          },
        ],
        series: [
          {
            color: "#cabb4e",
            smooth: true,
            type: "line",
            areaStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0.1,
                      color: "rgba(246,255,91,0.4)", // 0% 处的颜色
                    },
                    {
                      offset: 0.5,
                      color: "rgba(246,255,91, 0.3)", // 0% 处的颜色
                    },
                    {
                      offset: 0.7,
                      color: "rgba(246,255,91, 0.1)", // 0% 处的颜色
                    },
                    {
                      offset: 0.9,
                      color: "rgba(0,0,0, 0.1)", // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
              },
            },
            emphasis: {
              focus: "series",
            },
            data: valueList,
          },
        ],
      };

      this.myEchart.setOption(option);
    },
  },
};
</script>
<style lang="less" scoped></style>
