<template>
  <div>
    <div class="flex-aligh button-box">
      <Button>今年</Button>
      <Button>本周</Button>
      <a-range-picker
        :placeholder="['起始日期', '截止日期']"
        format="YYYY-MM-DD"
      >
        <span slot="suffixIcon">
          <a-icon type="calendar" />
        </span>
      </a-range-picker>

      <a-select placeholder="日报" style="width: 10vw">
        <a-select-option
          v-for="item in typeList"
          :key="item.value"
          :value="item.value"
          >{{ item.name }}</a-select-option
        >
      </a-select>
      <Button type="primary">本月</Button>
      <Button type="primary">查询</Button>
      <Button>重置</Button>
    </div>
    <div class="content-box flex">
      <Liquid />
      <Production />
    </div>
  </div>
</template>
<script>
import Liquid from "./Liquid";
import Production from "./Production";

export default {
  components: {
    Liquid,
    Production,
  },
  data() {
    return {
      typeList: [
        {
          name: "1",
          value: "1",
        },
        {
          name: "12",
          value: "12",
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.button-box {
  gap: 1vw;
  margin-bottom: 5vh;
}
.content-box {
  gap: 2vw;
}
</style>
